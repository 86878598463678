import React from 'react'
import Layout from '../../components/layout'
import PortfolioHeader from '../../components/portfolio-header'
import { graphql } from 'gatsby'
import Timeline from '../../components/timeline'

const AquiaPage = (props) => (
  <Layout>
    <Timeline />
    <article className='Portfolio'>
      <section className='Portfolio__intro'>
        <PortfolioHeader
          name='Acquia'
          position='Client Advisor'
          startDate='2010-02-01'
          endDate='2010-04-30'
          logo={props.data.companyLogo.childImageSharp.gatsbyImageData}
        />
        <p className='CompanyIntro'>
          Acquia is a company that provides enterprise products, services, and technical support for Drupal, an open-source content management platform.
        </p>
      </section>
      <section className='Portfolio__role'>
        <h2 className='SectionTitle'>My role</h2>
        <p>
          I joined Acquia’s support team as a Client Advisor. I was primarily responsible for assisting clients with their Drupal-based websites.
        </p>
        <ul className='List'>
          <li>
            Resolved client support requests by providing technical support involving Drupal, Drupal&nbsp;API, PHP, HTML, CSS, and more
          </li>
          <li>
            Remotely debugged websites, created fixes and offered solutions
          </li>
        </ul>
      </section>
    </article>
    <Timeline />
  </Layout>
)

export default AquiaPage

export const pageQuery = graphql`
  query {
    companyLogo: file(relativePath: { eq: "logos/acquia.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          width: 300
        )
      }
    }
  }
`
